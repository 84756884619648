import apiHandler from "@/services/Api.js"
import API from "@/constants/api-endpoints.js"
import AuthToken from '@/services/AuthToken'
import store from '@/store'
import router from '@/router'
import {questions} from '@/constants/consts'

const defaultStates = {
    questionVideoArr: [],
    currentQuestion: null,
    doneInstructions: false,
    questionDetail: {},
    questionSetWithChild: [],
    interviewQuestionCount: 0,
    allQuestions: [],
    allAnswersSummery:{},
    appLivestatus:'',
    loading: false
}
export default {
    state: defaultStates,
    getters: {
        questionVideoArr: state => state.questionVideoArr,
        currentQuestion: state => state.currentQuestion,
        doneInstructions: state => state.doneInstructions,
        currentQuestionDetail: state => state.questionDetail,
        questionSetWithChild: state => state.questionSetWithChild,
        interviewQuestionCount: state => state.interviewQuestionCount,
        allQuestions: state => state.allQuestions,
        appLivestatus:state => state.appLivestatus,
        allAnswersSummery:state => state.allAnswersSummery
    },
    mutations: {
        setInstruction(state, data) {
            state.doneInstructions = data
            state.loading = false
            state.error = null
        },
        currentQuestion(state, id) {
            state.currentQuestion = id
            state.loading = false
            state.error = null
        },
        setquestionVideoArr(state, data, skipType = 'auto') {
            const check = state.questionVideoArr.findIndex(x => x.que_id === data.que_id);
            console.log('check', check)
             if (check == '-1') {
                state.questionVideoArr.push(data)
             }
            state.loading = false
            state.error = null
        },
        setInterviewQuestion(state, {data, index}) {
            if (Array.isArray(data)) {
                data.forEach( element => {
                    console.log('element', element)
                     let check = state.questionSetWithChild.findIndex(x => x.que_id === element.que_id);
                        if (check == '-1') {
                            state.questionSetWithChild.push(element)
                        }
                })
                // state.questionSetWithChild.splice(index, 0, ...data);
                // console.log('state.questionSetWithChild', state.questionSetWithChild)

            } else {
                let check = state.questionSetWithChild.findIndex(x => x.que_id === data.que_id);
                if (check == '-1') { // state.questionSetWithChild.splice(index, 0, data);
                    state.questionSetWithChild.push(data)
                }
            }
            state.loading = false
            state.error = null
        },
        setInterviewQuestionCount(state, data) {
            console.log('state.interviewQuestionCount + data', state.interviewQuestionCount , '+',  data)
            state.interviewQuestionCount = state.interviewQuestionCount + data
            state.loading = false
            state.error = null
        },
        setquestionDetail(state, data) {
            state.questionDetail = data
            state.loading = false
            state.error = null
        },
        setquestions(state, data) {
            state.allQuestions = data
            state.loading = false
            state.error = null
        },
        setAppStatus(state, data) {
            state.appLivestatus = data
            state.loading = false
            state.error = null
        },
        setAnswersSummery(state, data) {
            state.allAnswersSummery = data
            state.loading = false
            state.error = null
        },
        resetQuestionData(state, data) {
            state.loggedInUser = null
            state.questionVideoArr = [];
            state.currentQuestion = null;
            state.questionDetail = {};
            state.questionSetWithChild = [];
            state.interviewQuestionCount = 0;
            state.allQuestions = [];
            state.allAnswersSummery = {};
            state.loggedInUser = null;
            state.doneInstructions = false
        },
        setLoading(state, data) {
            state.loading = data;
            state.error = null;
        },
        setError(state, data) {
            state.error = data;
            state.loading = false;
        },
        clearError(state) {
            state.error = null;
        }
    },
    actions: {
        async addQuestion({
            commit
        }, data, index) {
            commit("setInterviewQuestion", index);
            // return data
        },
        async setVideo({
            commit
        }, data) {
            commit("setLoading", true);
            commit("clearError");
            // const formData = { user_emailaddress: data.user_emailaddress, user_password: data.user_password, user_mpin : data.user_mpin }
            commit("currentQuestion", data.id)
            commit("setquestionVideoArr", data)
            // return data
        },
        async getQuestion({
            commit
        }, data) {
            const id = this.state.authData.loggedInUser.interview_id
            commit("setLoading", true);
            commit("clearError");

            // const questionsNew  = questions.map(x =>{
            //       x.isSkipped = null
            //       return x
            //     })
            // commit("setquestions",questions)
            // commit("setInterviewQuestionCount", questionsNew.length);
            // return

            await apiHandler.get(API.VIDEO_API_ENDPOINTS.GET_POST_QUESTIONS, {id: id}).then(res => {
                if (res.data.status) {
                    const userData = res.data.data
                    this.isLoading = false
                    const questionsNew = userData.allQuestions.map(x => {
                        x.isSkipped = null
                        return x
                    })
                    console.log(' questionsNew.length',  questionsNew.length)
                    commit("setquestions", questionsNew)
                    commit("setInterviewQuestionCount", questionsNew.length);

                    return questionsNew
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {
                commit("setError", err);
            });
            return data
        },
        async skipAutoQuestion({ commit }, data) {
            commit("setLoading", true);
            commit("clearError");
            let url
            if( data.skipType == 'auto') {
                url = API.VIDEO_API_ENDPOINTS.SKIP_AUTO_QUESTIONS
            } else if ( data.skipType == 'system_skipped') {
                url = API.VIDEO_API_ENDPOINTS.SKIPPED_QUESTIONS_BY_SYSTEM
            } else {
                url = API.VIDEO_API_ENDPOINTS.SKIP_QUESTIONS
            }

            // const url = data.skipType == 'auto' ? API.VIDEO_API_ENDPOINTS.SKIP_AUTO_QUESTIONS : API.VIDEO_API_ENDPOINTS.SKIP_QUESTIONS
            const formData = {
                interview_id: data.interview_id,
                question_id: data.question_id
            };
            const res = await apiHandler.post(url, formData).then(res => {
                if (res.data.status == true) {
                    this.isLoading = false
                    commit("clearError");
                    return res
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {
                commit("setError", err);
                return err
            });
            return res
        },
        async submitAnswer({
            commit
        }, data) {
            commit("setLoading", true);
            commit("clearError");
            await apiHandler.post(API.VIDEO_API_ENDPOINTS.SUBMIT_QUESTIONS_VIDEO, data).then(res => {
                console.log('res.data', res.data.data)
                if (res.data.status == true) {
                    const userData = res.data.data
                    this.isLoading = false
                    commit("clearError");
                    return userData
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {

                commit("setError", err);
            });
        },
        async setInterviewCompleted({
            commit
        }, data) {
            commit("setLoading", true);
            commit("clearError");
            await apiHandler.get(API.VIDEO_API_ENDPOINTS.CompletedInterview, data).then(res => {
                if (res.data.status == true) {
                    const userData = res.data.data
                    this.isLoading = false
                    commit("clearError");
                    return userData
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {
                commit("setError", err);
            });
        },
        async getInterviewsSummery({
            commit
        }, data) {
            commit("setAnswersSummery", null );
            commit("setLoading", true);
            commit("clearError");
            const id = this.state.authData.loggedInUser.interview_id
            await apiHandler.post(API.VIDEO_API_ENDPOINTS.getAnswerSummary, {}, {id:id}).then(res => {
                if (res.data.status == true) {
                    const resData = res.data.data
                    this.isLoading = false
                    commit("setAnswersSummery", resData );
                    commit("clearError");
                    return userData
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {
                commit("setError", err);
            });
        },
        async setQuestionViewed({
            commit
        }, data) {
            commit("setLoading", true);
            commit("clearError");
            const id = this.state.authData.loggedInUser.interview_id
            const qid = this.state.questionsVideo.questionDetail.que_id
            console.log('setQuestionViewed qid',qid )
            await apiHandler.post(API.VIDEO_API_ENDPOINTS.startQuestion,  {question_id:qid, interview_id:id}).then(res => {
                if (res.data.status == true) {
                    const resData = res.data.data
                    this.isLoading = false
                    return resData
                } else {
                    commit("setError", res.data);
                }
            }).catch(err => {
                commit("setError", err);
            });
        }
    }
};
