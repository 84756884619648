var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"topImg"},[_c('img',{staticClass:"img-fluid topDesign",attrs:{"src":require("../assets/images/top.png"),"alt":"top"}}),(
        _vm.loggedInUser &&
          _vm.loggedInUser.candidate_photo &&
          _vm.loggedInUser.candidate_photo != '' &&
          !_vm.imgError
      )?_c('img',{staticClass:" video-img img-fluid",staticStyle:{"border-radius":"50%","height":"70px","width":"70px"},attrs:{"src":_vm.serverFileStoragePath + _vm.loggedInUser.candidate_photo},on:{"error":function($event){return _vm.onImgError()}}}):_c('img',{staticClass:"video-img img-fluid",staticStyle:{"border-radius":"50%","height":"70px","width":"70px"},attrs:{"src":require("@/assets/images/user-dummy.png")}})]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"content content-page content-loder"},[_c('div',{staticClass:"hello-word"},[_c('p',[_vm._v("Hello")]),_c('h1',[_vm._v(_vm._s(_vm.loggedInUser.user_name)+"!")])]),(_vm.questionDetail)?_c('div',[_c('div',{staticClass:"phone-angle"},[_c('h2',[_vm._v("Question")]),(_vm.questionDetail)?_c('p',{staticClass:"que-para",domProps:{"innerHTML":_vm._s(_vm.questionDetail.que_question)}}):_vm._e(),_c('div',{staticClass:"que-time"},[_c('button',{staticClass:"recordBtn",attrs:{"disabled":_vm.isDataLoading},on:{"click":function($event){return _vm.recordVideo()}}},[_vm._v(" Record ")]),(
                !_vm.stopTimer &&
                  _vm.startTime != '' &&
                  _vm.endTime != '' &&
                  !_vm.isDataLoading
              )?_c('div',{staticClass:"que-time__innercountdown"},[_vm._m(0),_c('div',{},[_c('h4',[(_vm.startTime != '' && _vm.endTime != '' && !_vm.isDataLoading)?_c('vue-countdown-timer',{attrs:{"start-time":_vm.startTime,"end-time":_vm.endTime},on:{"start_callback":function($event){return _vm.startCallBack('event started')},"end_callback":function($event){return _vm.skipQuestion('auto')}},scopedSlots:_vm._u([{key:"countdown",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.props.minutes))]),_c('i',[_vm._v(_vm._s(scope.props.minutesTxt))]),_c('span',[_vm._v(_vm._s(scope.props.seconds))])]}},{key:"end-label",fn:function(scope){return [(
                          scope.props.startLabel !== '' &&
                            scope.props.tips &&
                            scope.props.labelPosition === 'end'
                        )?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(scope.props.startLabel)+":")]):_vm._e(),(
                          scope.props.endLabel !== '' &&
                            !scope.props.tips &&
                            scope.props.labelPosition === 'end'
                        )?_c('span',{staticStyle:{"color":"blue"}},[_vm._v(_vm._s(scope.props.endLabel)+":")]):_vm._e()]}}],null,false,1375187480)},[_c('template',{slot:"end-text"},[_c('span',{staticStyle:{"color":"green"}})])],2):_vm._e()],1)])]):_vm._e()])]),_c('div',{staticClass:"que-skip"},[_c('button',{attrs:{"disabled":_vm.isDataLoading},on:{"click":function($event){return _vm.skipQuestion('manual')}}},[_vm._v(" SKIP QUESTION ")]),_c('h6',[_c('span',[_vm._v(" ("+_vm._s(_vm.questionVideoArr.length + 1)+"/"+_vm._s(_vm.interviewQuestionCount)+") ")]),_c('h5',[_vm._v(_vm._s(_vm.skippedQuestionCount)+" skipped")])])])]):_vm._e(),(_vm.questions.length == 0 || _vm.questionDetail == undefined)?_c('div',{staticStyle:{"margin-top":"30px"}},[_c('p',[_vm._v("No questions available.")])]):_vm._e(),_c('Loader',{attrs:{"show-loader":_vm.isDataLoading,"isFullPage":true}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timer"},[_c('img',{attrs:{"src":require("../assets/images/timer-clock.png"),"alt":"timer-clock"}})])}]

export { render, staticRenderFns }