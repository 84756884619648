<template>
  <div>
    <div class="main-design">
      <div class="topImg">
        <img
          src="../assets/images/top.png"
          alt="top"
          class="img-fluid topDesign"
        />
      </div>
      <div class="container">
        <div class="content loginContent">
          <div class="login">
            <h1>Log In</h1>
            <p>
              Enter your details <br />
              for your video interview
            </p>
          </div>
          <div>
            <div class="otppage">
              <form v-on:submit.prevent>
                <div class="loginForm">
                  <div class="loginForm__input" v-if="!showOtp">
                    <label for="fname">Mobile Number</label><br />
                    <input
                      type="text"
                      id="monileNo"
                      name="mobileNo"
                      placeholder="Enter your mobile number"
                      maxLength="10"
                      isNumber
                      v-numeric-only
                      v-model="signInForm.mobile"
                    /><br />
                    <div
                      class="check_valid"
                      v-if="typesubmit && $v.signInForm.mobile.$error"
                    >
                      <span v-if="!$v.signInForm.mobile.required"
                        >This value is required.</span
                      >
                      <span v-if="!$v.signInForm.mobile.numeric"
                        >This value should be a valid number.</span
                      >
                      <span
                        v-if="
                          $v.signInForm.mobile.minLength &&
                            $v.signInForm.mobile.maxLength &&
                            !$v.signInForm.mobile.validnumber
                        "
                        >Please enter valid mobile number.
                      </span>
                      <span v-if="!$v.signInForm.mobile.minLength"
                        >The mobile no must be 10 digits.</span
                      >
                      <span v-if="!$v.signInForm.mobile.maxLength"
                        >The mobile no must be 10 digits.</span
                      >
                    </div>
                    <button
                      class="loginBtn"
                      @click="formSubmit()"
                      v-if="!showOtp"
                    >
                      Verify
                    </button>
                    <Loader
                      :show-loader="isDataLoading"
                      :isFullPage="false"
                      v-if="!showOtp"
                    />
                  </div>
                  <div class="check-code" v-if="showOtp">
                    <h4>Verify your mobile number</h4>
                    <p>
                      You will get SMS with an OTP on your registered mobile
                      number {{ signInForm.mobile }}
                    </p>
                  </div>
                  <div class="otpbox" v-if="showOtp">
                    <label for="otp">Otp</label><br />
                    <v-otp-input
                      ref="otpInput"
                      input-classes="otp-input"
                      separator=""
                      :num-inputs="4"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      @on-complete="submitOtp"
                    />
                    <Loader :show-loader="isDataLoading" :isFullPage="false" />
                    <div class="wait-time" v-if="showOtp">
                      <div v-if="showOtpTimer">
                        <p>You can request to resend OTP in next</p>
                        <span>
                          <vue-countdown-timer
                            v-if="startTime != '' && endTime != ''"
                            @end_callback="timeEndCallback()"
                            :start-time="startTime"
                            :end-time="endTime"
                            label-position="begin"
                            :minutesTxt="' min '"
                            :secondsTxt="' sec'"
                          >
                            <template slot="countdown" slot-scope="scope">
                              <span>{{ scope.props.minutes }}</span
                              ><i>{{ scope.props.minutesTxt }}</i>
                              <span>{{ scope.props.seconds }}</span>
                              <i>{{ scope.props.secondsTxt }}</i>
                              <!-- <span> mins</span> -->
                            </template>
                            <template slot="end-label" slot-scope="scope">
                              <span
                                style="color: red"
                                v-if="
                                  scope.props.startLabel !== '' &&
                                    scope.props.tips &&
                                    scope.props.labelPosition === 'end'
                                "
                                >{{ scope.props.startLabel }}:</span
                              >
                              <span
                                style="color: blue"
                                v-if="
                                  scope.props.endLabel !== '' &&
                                    !scope.props.tips &&
                                    scope.props.labelPosition === 'end'
                                "
                                >{{ scope.props.endLabel }}:</span
                              >
                            </template>
                            <template slot="end-text">
                              <span style="color: green"></span>
                            </template>
                          </vue-countdown-timer>
                        </span>
                      </div>
                      <br />
                      <button
                        type="button"
                        v-if="showResendOtp"
                        @click="formSubmit()"
                      >
                        Resend otp
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomImg"></div>
    </div>
  </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import { mapGetters, mapActions, mapState } from "vuex";
import {
  required,
  minLength,
  maxLength,
  numeric,
  helpers
} from "vuelidate/lib/validators";
import { checkSessionExpireTime } from "@/services/DateFormat.js";
const validnumber = helpers.regex("validnumber", /^[6-9]\d{9}$/);
import Loader from "@/components/Loader";
import { currentTime, setEndTime } from "@/services/DateFormat.js";
// import update from "../mixins/update.js"

export default {
  components: {
    "v-otp-input": OtpInput,
    Loader
  },
  // mixins: [update],
  data() {
    return {
      typesubmit: false,
      metaInfo: {
        title: "Login"
      },
      signInForm: {
        mobile: ""
      },
      showOtp: false,
      isDataLoading: false,
      showResendOtp: false,
      showOtpTimer: true,
      startTime: "",
      endTime: "",
      countDownSeconds: 120
    };
  },
  computed: {
    ...mapState(["authData"]),
    ...mapGetters(["loggedInUser", "loading", "error", "responseData",'internetStatus'])
  },
  beforeMount() {
    this.$store.commit("resetQuestionData");
  },
  // created() {
  //     // this.checkInternetConnectionG()
  // },
  validations: {
    signInForm: {
      mobile: {
        required,
        numeric,
        validnumber,
        minLength: minLength(10),
        maxLength: maxLength(10)
      }
    }
  },
  methods: {
    ...mapActions(["login", "verifyOtp"]),
    setTime() {
      this.showOtpTimer = true;
      this.startTime = currentTime();
      this.endTime = setEndTime(this.startTime, this.countDownSeconds);
    },
    async formSubmit() {
      this.typesubmit = true;
      this.showResendOtp = false;
      if(this.internetStatus == 'offline') {
         this.$toast.open({
            message: "Please check internet connection.",
            type: "error"
          });
          return
      }

      this.$v.$touch();

      if (!this.$v.signInForm.$invalid) {
        this.isDataLoading = true;

        await this.login(this.signInForm)
          .then(data => {
            this.typesubmit = false;
            this.isDataLoading = false;

            if (this.loggedInUser) {
              if (this.loggedInUser.interview_time) {
                const date1 = this.loggedInUser.interview_time;
                if (checkSessionExpireTime(date1)) {
                  this.$toast.open({
                    message: "Your interview session is expired.",
                    type: "error"
                  });
                  // this.$router.push("/");
                  return;
                } else {
                  this.showOtp = true;
                }
              } else {
                this.showOtp = true;
              }
              //set timer for resent otp
              this.setTime();
              // this.$router.push("/instruction");
            } else if (this.error) {
              console.log(this.error);
              this.$toast.open({
                message: this.error.message,
                type: "error"
              });
              // this.signOut();
              // this.$store.commit("resetQuestionData");
              // localStorage.removeItem("vuex");
              // this.$router.push("/");
            } else {
              // this.$toast.open({
              //   message:"Your interview not scheduled, kindly contact respected HR person.",
              //   type: "error"
              // });
            }
          })
          .catch(err => {
            this.isDataLoading = false;
            this.signInForm = {
              mobile: "",
              user_mpin: ""
            };
            // this.$toast.open({
            //   message:
            //     "Your interview not scheduled, kindly contact respected HR person.",
            //   type: "error"
            // });
          });
      }
    },
    async submitOtp(value) {
      console.log(this.test);
      this.typesubmit = true;
      this.signInForm.otp = value;
      this.$v.$touch();

      if (!this.$v.signInForm.$invalid) {
        this.isDataLoading = true;

        await this.verifyOtp(this.signInForm)
          .then(data => {
            this.typesubmit = false;
            if (this.loggedInUser.is_verified) {
              this.$router.push("/instruction-step-1");
            } else {
              this.handleClearInput();
              this.$toast.open({
                message: "Invalid Otp Entered",
                type: "error"
              });
            }
          })
          .catch(err => {
            this.signInForm = {
              mobile_no: "",
              otp: ""
            };
            this.$toast.open({
              message: "Something Went wrong",
              type: "error"
            });
            this.handleClearInput();
          });
        this.isDataLoading = false;
      }
    },
    timeEndCallback() {
      this.showResendOtp = true;
      this.showOtpTimer = false;
      // this.formSubmit()
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    }
  }
};
</script>

<style>
.otp-input {
  width: 35px;
  height: 35px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
* {
  box-sizing: border-box;
}

/* Full-width input fields */
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

input[type="text"]:focus,
input[type="password"]:focus {
  background-color: #ddd;
  outline: none;
}

hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for all buttons */
button {
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

button:hover {
  opacity: 1;
}

/* Extra styles for the cancel button */
.cancelbtn {
  padding: 14px 20px;
  background-color: #f44336;
}

/* Float cancel and signup buttons and add an equal width */
.cancelbtn,
.signupbtn {
  float: center;
  width: 50%;
  background-color: #36a8f4;
}

/* Add padding to container elements */
.container {
  padding: 16px;
  width: 50% !important;
}

/* Clear floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/* Change styles for cancel button and signup button on extra small screens */
@media screen and (max-width: 300px) {
  .cancelbtn,
  .signupbtn {
    width: 50%;
  }
}
</style>
