<template>
    <div class="instruction-detail">
        <div class="carousel-topImg">
            <img src="../assets/images/top.png" alt="top" class="img-fluid">
        </div>
        <div class="container">
            <div class="container-content conatiner-carousel instruction-page">
                <div class="carousel-heading">
                    <p>Hello</p>
                    <h1>{{loggedInUser.user_name}}!</h1>
                </div>
                <div class="instruc-img">
                    <img src="../assets/images/instruction.png" alt="instruction" class="img-fluid">
                </div>
                <div class="ins-content carousel__content">
                    <h2>Few Important Instructions </h2>
                    <p>Please read the instructions for seamless video interview</p>
                    <button class="btn buttonok" @click="setInstruction2()">OK</button>
                </div>
            </div>
        </div>
        <!-- <div class="overlay">
            <span class="overlay-content"></span>
            <div class="text">
                <div class="got">got it</div>
                <div class="dot"><span>.</span>..</div>
                <div class="next">next</div>
            </div>
          </div> -->
    </div>
</template>

<script lang="ts">
import { mapGetters, mapActions, mapState } from "vuex";

export default {
     name: "instru",
     computed: {
      ...mapState(["questionsVideo"]),
      ...mapGetters(["doneInstructions",'loggedInUser'])
    },
    beforeMount() {
      if (this.doneInstructions) {
        this.$router.push('/check-permission')
      }
    },
    methods: {
      setInstruction2() {
        console.log('setInstructionState')
        this.$router.push('/instruction')

      }
    }
}
</script>