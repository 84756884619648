<template>
  <div id="app">
    <div class="modal" id="exampleModalCenter" tabindex="-1"   :style="{'display': updateExists ? 'block' :  'none'}">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              New Update Available
            </h5>
          </div>
          <div class="modal-body">
            <h5>What's New:</h5>
            <ul class="list-unstyled">
              <li>1) Bug fixes</li>
              <li>2) Optimised User Experience</li>
            </ul>
          </div>
          <div class="modal-footer">
            <a
              href="javascript:void(0)"
              class="btn btn-form"
              @click="refreshApp"
              >Update</a>
          </div>
        </div>
      </div>
    </div>
     <install-modal />
      <router-view />
  </div>
</template>
<script>
import update from "./mixins/update";
import InstallModal from '@/components/installmodal.vue'
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "App",
  mixins: [update],
  data() {
    return {
      isStandAloneMode: false,
      showBackOnline: false,
      isAppActive:false
    };
  },
  components: {
    InstallModal
  },
  computed: {
    ...mapGetters(["internetStatus", 'appLivestatus'])
  },
  watch: {
     '$route': function (from, to) {
        this.checkInternetConnectionG()
      },
      internetStatus(newVal, oldVal) {
          if (newVal == 'offline') {
            this.$toast.open({
              message: "Please check internet connection.",
              type: "error"
            });
            this.$store.commit("setAppStatus", "lostInternetConnection");
            this.$store.commit('resetQuestionData')
            this.signOut()
            localStorage.removeItem("vuex");
            this.$router.push("/");
          }
      },
      appLivestatus(newVal, oldVal){
        this.isAppActive = newVal
        this.checkTime()
      }
  },
  mounted() {
    //  this.$router.onReady(() => this.checkInternetConnectionG());
    // this.$root.$on('on_app_load', this.appLoaded )
    if (window.matchMedia("(display-mode: standalone)").matches) {
      this.isStandAloneMode = true;
      console.log("display-mode is standalone");
    }
    this.checkTime()

  },
  created(){
    //  this.checkInternetConnectionG()
  },
  methods: {
    ...mapActions(['checkInternetConnection', 'signOut']),
    loadtheme() {
      const theme = this.$store.state.themename;
      this.themename = theme;
    },
    appLoaded() {
      this.$store.commit("setAppStatus", "apploaded");
    },
    async checkTime() {
      await this.checkInternetConnection()
      const test = this.isAppActive
      setTimeout(() => {
       if(test == 'visible' || test == 'apploaded') {
            this.checkTime()
        }
      }, 5000);
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
