<template>
  <div>
    <div class="topImg">
      <img
        src="../assets/images/top.png"
        alt="top"
        class="img-fluid topDesign"
      />
      <img
        :src="serverFileStoragePath + loggedInUser.candidate_photo"
        v-if="
          loggedInUser &&
            loggedInUser.candidate_photo &&
            loggedInUser.candidate_photo != '' &&
            !imgError
        "
        @error="onImgError()"
        class=" video-img img-fluid"
        style="border-radius:50%; height:70px;width:70px;"
      />
      <img
        src="@/assets/images/user-dummy.png"
        v-else
        class="video-img img-fluid"
        style="border-radius:50%; height:70px;width:70px;"
      />
    </div>
    <div class="container">
      <div class="content content-page content-loder">
        <div class="hello-word">
          <p>Hello</p>
          <h1>{{ loggedInUser.user_name }}!</h1>
        </div>
        <div v-if="questionDetail">
          <div class="phone-angle">
            <!-- <h2>Question {{ questionDetail.que_id }}</h2> -->
            <h2>Question</h2>
            <p
              class="que-para"
              v-if="questionDetail"
              v-html="questionDetail.que_question"
            ></p>
            <div class="que-time">
              <button
                class="recordBtn"
                @click="recordVideo()"
                :disabled="isDataLoading"
              >
                Record
              </button>
              <div
                class="que-time__innercountdown"
                v-if="
                  !stopTimer &&
                    startTime != '' &&
                    endTime != '' &&
                    !isDataLoading
                "
              >
                <div class="timer">
                  <img
                    src="../assets/images/timer-clock.png"
                    alt="timer-clock"
                  />
                </div>
                <div class="">
                  <h4>
                    <vue-countdown-timer
                      class=""
                      v-if="startTime != '' && endTime != '' && !isDataLoading"
                      @start_callback="startCallBack('event started')"
                      @end_callback="skipQuestion('auto')"
                      :start-time="startTime"
                      :end-time="endTime"
                    >
                      <template slot="countdown" slot-scope="scope">
                        <span>{{ scope.props.minutes }}</span
                        ><i>{{ scope.props.minutesTxt }}</i>
                        <span>{{ scope.props.seconds }}</span>
                        <!-- <span>sec</span> -->
                      </template>
                      <template slot="end-label" slot-scope="scope">
                        <span
                          style="color: red"
                          v-if="
                            scope.props.startLabel !== '' &&
                              scope.props.tips &&
                              scope.props.labelPosition === 'end'
                          "
                          >{{ scope.props.startLabel }}:</span
                        >
                        <span
                          style="color: blue"
                          v-if="
                            scope.props.endLabel !== '' &&
                              !scope.props.tips &&
                              scope.props.labelPosition === 'end'
                          "
                          >{{ scope.props.endLabel }}:</span
                        >
                      </template>
                      <template slot="end-text">
                        <span style="color: green"></span>
                      </template>
                    </vue-countdown-timer>
                  </h4>
                  <!-- <p>seconds left</p> -->
                </div>
              </div>
            </div>
          </div>
          <div class="que-skip">
            <button @click="skipQuestion('manual')" :disabled="isDataLoading">
              SKIP QUESTION
            </button>
            <h6>
              <span>
                ({{ questionVideoArr.length + 1 }}/{{ interviewQuestionCount }})
              </span>
              <h5>{{ skippedQuestionCount }} skipped</h5>
            </h6>
          </div>
        </div>
        <div
          style="margin-top:30px"
          v-if="questions.length == 0 || questionDetail == undefined"
        >
          <p>No questions available.</p>
        </div>
        <Loader :show-loader="isDataLoading" :isFullPage="true" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions, mapState } from "vuex";
import { currentTime, setEndTime } from "@/services/DateFormat.js";
import Loader from "@/components/Loader";
import { defaultTime, serverFileStoragePath } from "@/constants/consts";
import store from "@/store";
// import update from "@/mixins/update";

var checkAppStatus;
document.addEventListener(
  "visibilitychange",
  function() {
    if (document.hidden) {
      store.commit("setAppStatus", "hidden");
    } else {
      store.commit("setAppStatus", "visible");
    }
  },
  false
);

// document.addEventListener(
//   "beforeunload",
//   function() {
//     return false;
//     alert("The window is closing now!");
//   },
//   false
// );
// console.log(document);
// console.log(window);
// window.unload = function() {
//   var result = confirm("whether you want to leave this page");
//   if (result) {
//     alert("Thank you for visiting");
//   } else {
//     alert("Thank you for staying with us");
//   }
// };
window.addEventListener("beforeunload", function(e) {
  // the absence of a returnValue property on the event will guarantee the browser unload happens
  delete e["returnValue"];
});

export default {
  name: "Questions",
  components: {
    Loader
  },
  // mixins: [update],
  data() {
    return {
      questions: [],
      questionId: "",
      showrecord: false,
      questionDetail: {},
      questionIndex: 0,
      skippedQuestionCount: 0,
      startTime: "",
      endTime: "",
      isDataLoading: false,
      stopTimer: true,
      timeLimit: defaultTime.time_to_read,
      totalQuestion: 0,
      serverFileStoragePath: serverFileStoragePath,
      checkAppLiveStatus: checkAppStatus,
      imgError: false,
      skipOnWatch: true
    };
  },
  watch: {
    async appLivestatus(value, oldValue) {
      if(this.skipOnWatch == true ) {
        if (value == "hidden") {
          this.stopTimer = true;
        }
        console.log( 'value  ', value,   ' oldValue ' , oldValue, ' this.questionVideoArr.length ', this.questionVideoArr.length)

        if (value == "visible" && this.questionDetail && oldValue != 'apploaded' && oldValue == 'hidden' && this.questionVideoArr.length > 0) {
          console.log('if 1', value)
          this.stopTimer = false;
          await this.skipQuestion("system_skipped");
        }

        if ( oldValue == 'apploaded' && value == "visible" && this.questionVideoArr.length > 0) {
          console.log('if 2', value, '  this.questionVideoArr.length ', this.questionVideoArr.length)
          await this.skipQuestion("system_skipped");
        }

        if ( oldValue == 'lostInternetConnection' && value == "visible" && this.questionVideoArr.length > 0) {
          console.log('if 3  oldValue ', oldValue)
          await this.skipQuestion("system_skipped");
        }
      }
    },
    internetStatus(newVal, oldVal) {
      if (newVal == "offline") {
        this.$store.commit("setAppStatus", "lostInternetConnection");
      }
    }
  },
  computed: {
    filename() {
      return this.imgErraor
        ? serverFileStoragePath + loggedInUser.candidate_photo
        : "../assets/images/user-dummy.png";
    },
    ...mapState(["authData", "questionsVideo"]),
    ...mapGetters([
      "loggedInUser",
      "questionVideoArr",
      "currentQuestion",
      "loading",
      "error",
      "allQuestions",
      "questionSetWithChild",
      "interviewQuestionCount",
      "currentQuestionDetail",
      "appLivestatus",
      "isUserSessionValie"
    ]),
    totalQuestionCount: function() {
      // `this` points to the vm instance
      //  this.totalQuestion =  this.questions.length  + this.questionDetail.childQuestions.length
      // return  this.allQuestions.length  + this.questionDetail['childQuestions'].length
      return 0;
    }
  },
  async beforeMount() {
    // await this.validateUser()
    this.questions = this.allQuestions;
    return;
    const res = this.isUserSessionValie;
    console.log("res", res);
    //  this.questions = this.allQuestions;
    if (res && res == true) {
      this.questions = this.allQuestions;
      console.log("beforeMount this.question .........", this.questions);
    } else {
      console.log("elsee beforemount");
      // await this.signOut();
      // this.$store.commit('resetQuestionData')
      // localStorage.removeItem("vuex");
      // this.$router.push("/");
    }
  },
  async mounted() {
    // this.$root.$on('on_app_load', this.onAppLoaded )

    this.checkAppLiveStatus = this.checkAppStatus;

    if (this.questionVideoArr.length == 0) {
      this.questionDetail = this.questions[this.questionIndex];
      this.$store.commit("setquestionDetail", this.questionDetail);
      await this.setQuestionViewed();
    } else {
      const results = this.questions.filter(
        ({ que_id: id1 }) => !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1));

      const remaningquestion = this.questionSetWithChild.filter(
        ({ que_id: id1 }) => !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1));

      if (results.length == 0 && remaningquestion.length == 0) {
        this.$router.push("/thankyou");
        return;
      } else if (remaningquestion.length > 0) {
        const qdata = remaningquestion[0]; // this.currentQuestionDetail;
        this.questionDetail = remaningquestion[0]; // this.currentQuestionDetail;
      } else if (results.length > 0) {
        const qdata = results[0]; // this.currentQuestionDetail;
        this.questionDetail = results[0]; // this.currentQuestionDetail;
      }

      
      this.isDataLoading = false;
    }

    if (this.questionDetail) {
      this.$store.commit("setquestionDetail", this.questionDetail);
      this.questionIndex = this.questions.findIndex(
        x => x.que_id === this.questionDetail.que_id
      );
      await this.setQuestionViewed();
      this.setTimer(this.questionDetail.time_to_read);
    } else {
      await this.signOut();
      this.$store.commit("resetQuestionData");
      localStorage.removeItem("vuex");
      this.$router.push("/");
    }

    const count = this.questionVideoArr.filter(
      x =>
        x.isSkipped === true ||
        x.auto_skipped == "1" ||
        x.is_skipped == "1" ||
        x.system_skipped == "1"
    );
    this.skippedQuestionCount = count.length;
    this.isDataLoading = false;
  },
  deactivated() {
    console.log("deactivated");
  },
  beforeUnmount() {
    console.log("beforeUnmount");
  },
  unmounted() {
    console.log("unmounted");
  },
  created() {
    store.commit("setAppStatus", "visible");
    window.addEventListener("beforeunload", this.beforePageDestroyed);
  },
  ready() {
    store.commit("setAppStatus", "visible");
    window.addEventListener("beforeunload", this.beforePageDestroyed);
  },
  methods: {
    ...mapActions(["skipAutoQuestion", "validateUser", "signOut",'setQuestionViewed']),
    beforePageDestroyed: function(event) {
      store.commit("setAppStatus", "visible");
      window.onbeforeunload = function() {
        return "Are you really want to perform the action?";
      };
    },
    onAppLoaded() {},
    onImgError() {
      this.imgError = true;
    },
    imageUrlAlt(event) {
      event.target.src = "../assets/images/user-dummy.png";
    },
    startCallBack: function(x) {},
    async skipQuestion(skipType) {
      if (this.questionDetail != null) {
        const qId =
          skipType == "manual"
            ? [this.questionDetail.que_id]
            : this.questionDetail.que_id;
        const formData = {
          interview_id: this.loggedInUser.interview_id,
          question_id: qId,
          skipType: skipType
        };

        const data = this.questionDetail;
        const qdata = this.questionDetail;
        this.isDataLoading = true;
        let resData = await this.skipAutoQuestion(formData)
          .then(res => {
            if (res.status == 200) {
              console.log(res.data.data)
              data.isSkipped = true;
              data.videoId = "";
              this.$store.dispatch("setVideo", data);
              const count = this.questionVideoArr.filter(
                x =>
                  x.isSkipped === true ||
                  x.auto_skipped == "1" ||
                  x.is_skipped == "1" ||
                  x.system_skipped == "1"
              );
              this.skippedQuestionCount = count.length;
              this.isDataLoading = false;

              const remaningquestion = this.questionSetWithChild.filter(
                ({ que_id: id1 }) =>
                  !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1)
              );

              if (remaningquestion.length > 0) {
                this.questionDetail = remaningquestion[0];
              } else {
                const results = this.questions.filter(
                  ({ que_id: id1 }) =>
                    !this.questionVideoArr.some(
                      ({ que_id: id2 }) => id2 === id1
                    )
                );
                if (results.length > 0) {
                  this.questionDetail = results[0];
                } else {
                  console.log("all questions done");
                  this.$router.push("/thankyou");
                  return;
                }
              }
              console.log('skippimg question ----------------------')
              this.$store.commit("setquestionDetail", this.questionDetail);
              // this.setQuestionViewed();
              this.setTimer(this.questionDetail.time_to_read);
            } else {
              this.$toast.open({
                message: "Something went wrong.",
                type: "error"
              });
              this.signOut();
              this.$store.commit("resetQuestionData");
              localStorage.removeItem("vuex");
              this.$router.push("/");
            }
          })
          .catch(err => {
            this.$toast.open({
              message: "Something went wrong.",
              type: "error"
            });
            this.signOut();
            this.$store.commit("resetQuestionData");
            localStorage.removeItem("vuex");
            this.$router.push("/");
          });
          console.log('resData', resData)
        await  this.setQuestionViewed();
        this.isDataLoading = false;
      }
    },
    checkChildQuestion(qdata, index = 0) {
      if (qdata.childQuestions.length > 0) {
        this.$store.commit(
          "setInterviewQuestionCount",
          qdata.childQuestions.length
        );
        this.$store.commit("setInterviewQuestion", {
          data: qdata.childQuestions,
          index: index + 1
        });
      } else {
        this.$store.commit("setInterviewQuestionCount", 1);
        this.$store.commit("setInterviewQuestion", {
          data: qdata,
          index: index + 1
        });
      }
    },
    async recordVideo() {
      const res = await this.validateUser();
      if (res && res.status == true) {
        // console.log('validateUser', res)
        // this.$store.commit("setquestionDetail", this.questionDetail);
        // await this.setQuestionViewed();
        this.$router.push({ path: "recording" });
      } else {
        await this.signOut();
        this.$store.commit("resetQuestionData");
        localStorage.removeItem("vuex");
        this.$router.push("/");
      }
    },
    setTimer(time_to_read) {
      this.stopTimer = false;
      this.startTime = currentTime();
      const endTimeLimit =  time_to_read && time_to_read > 20 ? time_to_read : this.timeLimit;
      this.endTime = setEndTime(this.startTime, endTimeLimit);
    }
    // async setViewedQuestion(){
    //   const res = await this.skipAutoQuestion(formData)
    //   .then(res => {
    //     console.log("dd")
    //   })
    // }
  }
};
</script>
