
export default {
  getToken() {
    const authToken = localStorage.getItem('authToken')
    return authToken != null && authToken != '' ? authToken : false
  },
  saveToken(authToken) {
    authToken = `${authToken}`
    localStorage.setItem('authToken', authToken)
  },
  removeToken() {
    localStorage.removeItem('authToken')
  },
  // addUserDetails(userDetails) {
  //   localStorage.setItem('userDetails', userDetails)
  // },
  logoutUser() {
    this.removeToken()
    // localStorage.removeItem('userDetails')
    localStorage.removeItem('vuex')
  },
  // getUserDetails(param = null) {
  //   const useDetails = JSON.parse(localStorage.getItem('userDetails'))
  //   return (param == null) ? useDetails : (param in useDetails ? useDetails.param : null)
  // }
}
