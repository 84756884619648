var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main-design"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"content loginContent"},[_vm._m(1),_c('div',[_c('div',{staticClass:"otppage"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"loginForm"},[(!_vm.showOtp)?_c('div',{staticClass:"loginForm__input"},[_c('label',{attrs:{"for":"fname"}},[_vm._v("Mobile Number")]),_c('br'),_c('input',{directives:[{name:"numeric-only",rawName:"v-numeric-only"},{name:"model",rawName:"v-model",value:(_vm.signInForm.mobile),expression:"signInForm.mobile"}],attrs:{"type":"text","id":"monileNo","name":"mobileNo","placeholder":"Enter your mobile number","maxLength":"10","isNumber":""},domProps:{"value":(_vm.signInForm.mobile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.signInForm, "mobile", $event.target.value)}}}),_c('br'),(_vm.typesubmit && _vm.$v.signInForm.mobile.$error)?_c('div',{staticClass:"check_valid"},[(!_vm.$v.signInForm.mobile.required)?_c('span',[_vm._v("This value is required.")]):_vm._e(),(!_vm.$v.signInForm.mobile.numeric)?_c('span',[_vm._v("This value should be a valid number.")]):_vm._e(),(
                        _vm.$v.signInForm.mobile.minLength &&
                          _vm.$v.signInForm.mobile.maxLength &&
                          !_vm.$v.signInForm.mobile.validnumber
                      )?_c('span',[_vm._v("Please enter valid mobile number. ")]):_vm._e(),(!_vm.$v.signInForm.mobile.minLength)?_c('span',[_vm._v("The mobile no must be 10 digits.")]):_vm._e(),(!_vm.$v.signInForm.mobile.maxLength)?_c('span',[_vm._v("The mobile no must be 10 digits.")]):_vm._e()]):_vm._e(),(!_vm.showOtp)?_c('button',{staticClass:"loginBtn",on:{"click":function($event){return _vm.formSubmit()}}},[_vm._v(" Verify ")]):_vm._e(),(!_vm.showOtp)?_c('Loader',{attrs:{"show-loader":_vm.isDataLoading,"isFullPage":false}}):_vm._e()],1):_vm._e(),(_vm.showOtp)?_c('div',{staticClass:"check-code"},[_c('h4',[_vm._v("Verify your mobile number")]),_c('p',[_vm._v(" You will get SMS with an OTP on your registered mobile number "+_vm._s(_vm.signInForm.mobile)+" ")])]):_vm._e(),(_vm.showOtp)?_c('div',{staticClass:"otpbox"},[_c('label',{attrs:{"for":"otp"}},[_vm._v("Otp")]),_c('br'),_c('v-otp-input',{ref:"otpInput",attrs:{"input-classes":"otp-input","separator":"","num-inputs":4,"should-auto-focus":true,"is-input-num":true},on:{"on-complete":_vm.submitOtp}}),_c('Loader',{attrs:{"show-loader":_vm.isDataLoading,"isFullPage":false}}),(_vm.showOtp)?_c('div',{staticClass:"wait-time"},[(_vm.showOtpTimer)?_c('div',[_c('p',[_vm._v("You can request to resend OTP in next")]),_c('span',[(_vm.startTime != '' && _vm.endTime != '')?_c('vue-countdown-timer',{attrs:{"start-time":_vm.startTime,"end-time":_vm.endTime,"label-position":"begin","minutesTxt":' min ',"secondsTxt":' sec'},on:{"end_callback":function($event){return _vm.timeEndCallback()}},scopedSlots:_vm._u([{key:"countdown",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.props.minutes))]),_c('i',[_vm._v(_vm._s(scope.props.minutesTxt))]),_c('span',[_vm._v(_vm._s(scope.props.seconds))]),_c('i',[_vm._v(_vm._s(scope.props.secondsTxt))])]}},{key:"end-label",fn:function(scope){return [(
                                scope.props.startLabel !== '' &&
                                  scope.props.tips &&
                                  scope.props.labelPosition === 'end'
                              )?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(scope.props.startLabel)+":")]):_vm._e(),(
                                scope.props.endLabel !== '' &&
                                  !scope.props.tips &&
                                  scope.props.labelPosition === 'end'
                              )?_c('span',{staticStyle:{"color":"blue"}},[_vm._v(_vm._s(scope.props.endLabel)+":")]):_vm._e()]}}],null,false,365680624)},[_c('template',{slot:"end-text"},[_c('span',{staticStyle:{"color":"green"}})])],2):_vm._e()],1)]):_vm._e(),_c('br'),(_vm.showResendOtp)?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.formSubmit()}}},[_vm._v(" Resend otp ")]):_vm._e()]):_vm._e()],1):_vm._e()])])])])])]),_c('div',{staticClass:"bottomImg"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topImg"},[_c('img',{staticClass:"img-fluid topDesign",attrs:{"src":require("../assets/images/top.png"),"alt":"top"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('h1',[_vm._v("Log In")]),_c('p',[_vm._v(" Enter your details "),_c('br'),_vm._v(" for your video interview ")])])}]

export { render, staticRenderFns }