<template>
  <div>
    <div class="topImg">
      <img src="../assets/images/top.png" alt="top" class="img-fluid" />
      <!-- <img :src="serverFileStoragePath + loggedInUser.candidate_photo" v-if="loggedInUser && loggedInUser.candidate_photo != ''" alt="video-image" class="img-fluid video-img" style="border-radius:50%; height:70px;width:70px;"/> -->
      <!-- <img src="@/assets/images/user-dummy.png" v-else alt="video-image" class="img-fluid video-img" /> -->
    </div>
    <div class="container">
      <div class="content">
        <div class="hello-word">
          <p>Thank You</p>
       <h1>{{loggedInUser ? loggedInUser.user_name : ''}}!</h1>
        </div>
        <div class="interview_que">
          <h3>Interview question</h3>
          <div class="lastQuestion">
            <ul>
              <li v-for="(qsn, index) in allAnswersSummery" :key="index"
                :class="{'questionView__wrong': qsn.isSkipped == true ||  qsn.auto_skipped == '1' || qsn.is_skipped == '1' || qsn.system_skipped == '1'}"><a href="">{{index+1}}</a></li>
            </ul>
          </div>
          <p>
            The HR will call you back <br />
            once the interview is <br />
            reviewed.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from "vuex";
  import { serverFileStoragePath } from "@/constants/consts";

  export default {
    name: "Thank-you",
    components: {
      //HelloWorld
    },
    data() {
      return {
        questionId: "",
        showrecord: false,
        quesionDetail: {},
        questionIndex: 0,
        countDown: 20,
        serverFileStoragePath: serverFileStoragePath
      };
    },
    computed: {
      ...mapState(["authData", "questionsVideo"]),
      ...mapGetters([
        "loggedInUser",
        "questionVideoArr",
        "currentQuestion",
        "loading",
        'allAnswersSummery',
        "error"
      ])
    },
    async mounted() {
      await this.getInterviewsSummery()
      await this.setInterviewCompleted({id:this.loggedInUser.interview_id})
      this.countDownTimer();
    },
    watch: {
      async countDown(value) {
        if(value == 0) {
          this.$store.commit('resetQuestionData')
          await this.signOut()
          localStorage.removeItem("vuex");
          this.$router.push("/");
        }
      }
    },
    methods: {
      ...mapActions(["signOut",'setInterviewCompleted', 'getInterviewsSummery']),
       countDownTimer() {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1;
            this.countDownTimer();
          }, 1000);
        }
      },
      onImgError(){
        this.imgError = true;
      },
    }
  };
</script>