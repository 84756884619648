import * as moment from 'moment'
let checkSession = false
const addExpiryHours = 3

export function currentTime() {
    return moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
}

export function setEndTime(date, seconds) {
    var startdate = moment(date);
    return moment(startdate).add(seconds, 'seconds').format("YYYY-MM-DD HH:mm:ss");
}

export function timeFormat(date) {
    return moment(date).format(" HH:mm:ss");
}
export function timeDiff(startTime) {
    // const time  = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    // return time.diff(startTime, 'seconds');

    var a = moment();
    // var b = moment().add(1, 'seconds');
    return a.diff(startTime, 'seconds')
    // -1000
    // return duration.asSeconds();
}

export function checkSessionExpireTime(startTime) {
    const currentTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const date1 = new Date(startTime);
    var startdate = moment(date1);
    const addHour = startdate.add(addExpiryHours, 'hours').format("YYYY-MM-DD HH:mm:ss");
    if (checkSession) {
        return currentTime > addHour ? true : false
    } else {
        return false
    }
}
export class MyDate {
    newDate
    constructor(date) {
        if (date) {
            this.newDate = new Date(date);
        } else {
            this.newDate = new Date();
        }
    }

    getFullYear() {
        return this.newDate.getUTCFullYear();
    }

    getMonth() {
        return this.newDate.getUTCMonth();
    }

    getDate() {
        return this.newDate.getUTCDate();
    }
}
