<template>
  <div class="home">
      <div class="main-design">
        <div class="topImg">
          <img src="../assets/images/top.png" alt="top" class="img-fluid topDesign">
        </div>
        <div class="container">

      <div class="content">
        <div class="logoNeosoft">
          <img src="../assets/images/neosoft.svg" alt="neosoft" class="img-fluid">
        </div>
        <div class="dream-text">
          <h1>Dream Job <br> Interview Starts</h1>
          <p>Get started with your Interview</p>
          <router-link to="/login"><button class="getBtn">Let's get started</button></router-link>
        </div>
      </div>
    </div>
    <div class="bottomImg">
    </div>
  </div>
  </div>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: 'Home',
  }
</script>