import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import recording from '@/views/recording'
import test_recording from '@/views/test_recording'
import signIn from '@/views/SignIn'
import Instruction2 from '@/views/Instruction2'
import Instruction1 from '@/views/Instructions1'
import Questions from '@/views/Questions'
import thankyou from '@/views/thankyou'

import store from "@/store"
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/login',
        component: signIn
    },
    {
        path: '/instruction-step-1',
        component: Instruction1,
        meta: {
            authRequired: true
        }
    },
    {
        path: '/instruction',
        component: Instruction2,
        meta: {
            authRequired: true
        }
    },
    {
        path: '/test-voice',
        component: () => import ('@/views/TestVoice.vue'),
        // component: TestVoice,
        // meta: {
        //     authRequired: true
        // }
    },
    {
        path: '/questions',
        component: Questions,
        meta: {
            authRequired: true
        }
    },
    {
        path: '/recording',
        component: recording,
        meta: {
            authRequired: true
        }
    },
    {
        path: '/test_recording',
        component: test_recording,
    },
     {
        path: '/check-permission',
        component: () => import ('@/views/CheckPermission.vue'),
        meta: {
            authRequired: true
        }
    }, {
        path: '/thankyou',
        component: thankyou,
        meta: {
            authRequired: true
        }
    }, {
        path: '**',
        component: () => import ('@/views/NotFound.vue')
    },
]
const router = new VueRouter({mode: 'history', base: process.env.BASE_URL, routes})
const waitForStorageToBeReady = async (to, from, next) => {
    await store.restored
}
// Before each route evaluates...
router.beforeEach((to, from, next) => {
    waitForStorageToBeReady()
    const authRequired = to.matched.some((route) => route.meta.authRequired)
    const authToken = localStorage.getItem('authToken')
    const isLoggedIn = store.state.authData.loggedInUser && store.state.authData.loggedInUser.is_verified == true && authToken != '' && authToken != null

    if ((to.path == '/' || to.path == '/login') && store.state.authData && store.state.authData.loggedInUser != null && store.state.authData.loggedInUser.is_verified == true && authToken != '' && authToken != null) {
        return next("/instruction-step-1")
    } else if (authRequired && ! isLoggedIn) {
        return next("/login")
    } else {
        return next();
    }
});


export default router
