import axios from 'axios'
import AuthToken from './AuthToken'
const API_URL = process.env.VUE_API_BASE_URL
import store from '@/store'
import router from '@/router'
const token = AuthToken.getToken()

const axiosApi = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization':'Bearer ' +' localStorage.auth_token'
    }
})

export default {
    getApiUrl() {
        return API_URL
    },
    async get(url, passPayload = 0) {
        this.addHeaders()
        // const token =  AuthToken.getToken()
        // const yourConfig = {
        // headers: {
        //       Authorization: "Bearer " + token
        // }
        // }
        // console.log(axios)
        // return  await axios.get(url, yourConfig, {
        // params: passPayload
        // });
        // axiosApi.defaults.headers.params  = AuthToken.getToken()
        // const config = { headers: { Authorization : 'Bearer ' + token }}
        // return await axios.get('https://careers.neosofttech.com/api/web/v1/vapp/get-questions?id=U0ZKQmRIUmxibVJoYm1ObFVHOXlkR0ZzTHpRPQ9' , {
        // headers: {
        //     "Authorization" : `Bearer ${token}`,
        //     "Access-Control-Allow-Origin": "*",
        //     'Content-Type': 'application/json',
        //      'responseType': 'application/json'
        // } })
        // return await axios.get('https://careers.neosofttech.com/api/web/v1/vapp/get-questions?id=U0ZKQmRIUmxibVJoYm1ObFVHOXlkR0ZzTHpRPQ9' )
        // return axiosApi.get('https://careers.neosofttech.com/api/web/v1/vapp/get-questions?id=U0ZKQmRIUmxibVJoYm1ObFVHOXlkR0ZzTHpRPQ9',{}, config).then(respose => {
        // return respose
        // })
        // return
        return axiosApi.get(url, {params: passPayload}).then(respose => {
            if (respose.data.data.authorizationFailed) {
                store.commit('setUser', '')
                AuthToken.logoutUser()
                router.push('/login')
            }
            return respose
        }).catch(err => {
            if (err.response.status === 401) {
                store.commit('AUTH_USER', '')
                AuthToken.logoutUser()
                router.push('/login')
            }
        })
    },
    post(url, payload, queryParam = '') {
        this.addHeaders()
        let qparam = ''
        if (queryParam) {
            qparam = {
                params: queryParam
            }
        }
        return axiosApi.post(url, payload, qparam).then(respose => {
            if (respose.data.data.authorizationFailed) {
                store.commit('setUser', '')
                AuthToken.logoutUser()
                router.push('/login')
            }
            return respose
        }).catch(err => {
            if (err.response.status === 401) {
                store.commit('AUTH_USER', '')
                AuthToken.logoutUser()
                router.push('/login')
            } else {
                return err.response
            }
        })
    },
    put(url, payload, passPayload = 0) {
        this.addHeaders()
        return axiosApi.put(url, payload).then(respose => {
            if (respose.data.data.authorizationFailed) {
                store.commit('setUser', '')
                AuthToken.logoutUser()
                router.push('/login')
            }
            return respose
        }).catch(err => {
            if (err.response.status === 401) {
                store.commit('AUTH_USER', '')
                AuthToken.logoutUser()
                router.push('/login')
            } else {
                return err.response
            }
        })
    },
    delete(url, payload) {
        this.addHeaders()
        return axiosApi.delete(url, {params: payload}).then(respose => {
            return respose
        }).catch(err => {
            if (err.response.status === 401) {
                store.commit('AUTH_USER', '')
                AuthToken.logoutUser()
                router.push('/login')
            } else {
                return err.response
            }
        })
    },
    upload(url, payload, passPayload = 0) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return axiosApi.post(url, payload, config).then(respose => {
            return respose
        })
    },
    addpayloadtourl(url) {
        return url
    },
    addHeaders() {
        const token = AuthToken.getToken()
        // console.log(axiosApi)
        // console.log(axiosApi.defaults)
        if (token) {
            axiosApi.defaults.headers.common['Authorization'] = 'Bearer ' + token

        }
        // axiosApi.defaults.headers.common['Authorization'] = AuthToken.getToken()
    },
    addParam() {
        axiosApi.defaults.headers.params = AuthToken.getToken()
    },
    appendParams(originalOptions, paramsObj) {
        let params = new URLSearchParams();

        for (const key in paramsObj) {
            if (paramsObj.hasOwnProperty(key)) {
                if (key === 'offset') {
                    const val = paramsObj[key] / 10;
                    params = params.append('offset', val.toString());
                } else {
                    params.append(key, paramsObj[key]);
                }
            }
        }
        axiosApi.params = params
    }
}
