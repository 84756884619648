<template>
  <loading
    :active.sync="shouldLoad"
    :can-cancel="true"
    :color="themeColor"
    :is-full-page="fullloader"
    :height="heightValue"
  />
</template>

<script>
import Loading from "vue-loading-overlay";
// import { themeConstants } from '@/constants/consts.js'

export default {
  name: "Loader",
  components: {
    Loading
  },
  props: {
    showLoader: {
      required: true,
      type: Boolean,
      default: false
    },
    isFullPage: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      // themeConstants: themeConstants,
      themeColor: "#fd6420",
      shouldLoad: false,
      fullloader: true,
      heightValue: 64
    };
  },
  watch: {
    showLoader: {
      immediate: true,
      handler(value) {
        this.shouldLoad = value;
      }
    },
    isFullPage(value) {
      this.fullloader = value;
    },
     height: {
      immediate: true,
      handler(value) {
        this.heightValue = value;
      }
    },
  },
  beforeMount() {
    // if (this.$store.state.themename) {
    //   const colorCode = themeConstants.themeColor.find(x => x.value === this.$store.state.themename)
    //   if (colorCode && colorCode.key !== '') {
    //     this.themeColor = colorCode.key
    //   }
    // }
  }
};
</script>
