<template>
  <div class="over-shed">
    <!-- <div class="overlay">
      <span class="overlay-content"></span>
      <div class="text">
        <div class="got">got it</div>
        <div class="dot"><span>.</span>..</div>
        <div class="next">next</div>
      </div>
    </div>  -->
    <!-- carousel pages -->
    <div class="carousel-topImg">
      <img src="../assets/images/top.png" alt="top" class="img-fluid">
    </div>
    <div class="container">
      <div class="container-content conatiner-carousel">
        <div class="carousel-heading">
          <p>Hello</p>
          <h1>{{loggedInUser.user_name}}!</h1>
        </div>

        <carousel :per-page="1" class="banner-carousel active" :autoplay="true" :loop="false">
          <slide class="banner__content">
            <div class="row align-items-center" style="margin-left:0 ;margin-right:0;">
              <div class="helloImg">
                <span></span>
                <img src="../assets/images/hello_kartik.svg" alt="hello_kartik" class="img-fluid">
              </div>
              <div class="carousel__content">
                <h2>Phone Angle</h2>
                <p>Keep the phone in such an angle
                  So we can view and listen to
                  Your answers properly.</p>
              </div>
            </div>
          </slide>

          <slide class="banner__content">
            <div class="row align-items-center active" style="margin-left:0 ;margin-right:0;">
              <div class="helloImg">
                <!-- <img src="../assets/images/think-img.svg" alt="think-img" class="img-fluid" style="height: 215px;"> -->
                <img src="../assets/images/think-img.svg" alt="think-img" class="img-fluid" style="height: 270px;">
                <!-- <button type="button submit" class=" btn proccedBtn" @click="setInstructionState()">Skip & proceed</button> -->
                <!-- <button type="button submit" class=" btn proccedBtn">Skip & proceed</button> -->
              </div>
              <div class="carousel__content">
                <!-- <h2 @click="setInstructionState()">Skip Option</h2> -->
                <h2>Skip Option</h2>
                <p>
                  You can skip a question &
                  answer the next question
                </p>
              </div>
            </div>
          </slide>
           <slide class="banner__content">
            <div class="row align-items-center active" style="margin-left:0 ;margin-right:0;">
              <div class="helloImg">
                <img src="@/assets/images/timer.svg" alt="all-the-best" class="img-fluid">
              </div>
              <div class="carousel__content">
                <h2>Auto Skip</h2>
                <p>
                 Start recording your answer.<br> If your timer ends the question will <br> be skipped automatically.
                </p>
              </div>

            </div>
            <!-- <button class="startBtn" @click="setInstructionState()">Start Interview</button> -->
          </slide>
          <slide class="banner__content">
            <div class="row align-items-center active" style="margin-left:0 ;margin-right:0;">
              <div class="helloImg">
                <img src="@/assets/images/all-the-best.svg" alt="all-the-best" class="img-fluid">
              </div>
              <div class="carousel__content">
                <h2>All The Best</h2>
                <p>
                  Press the Start button to <br>
                  Start the interview
                </p>
              </div>

            </div>
            <button class="startBtn" @click="setInstructionState()">Start Interview</button>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src

  import { Carousel, Slide } from 'vue-carousel'
  import { mapGetters, mapActions, mapState } from "vuex";

  export default {
    name: 'HomePage',
    components: {
      Carousel,
      Slide,
    },
    computed: {
      ...mapState(["questionsVideo"]),
      ...mapGetters(["doneInstructions",'loggedInUser'])
    },
    beforeMount() {
      if (this.doneInstructions) {
        this.$router.push('/check-permission')
        // this.$router.push('/test-voice')
      }
    },
    methods: {
      setInstructionState() {
        this.$store.commit('setInstruction', true)
        this.$router.push('/check-permission')
        // this.$router.push('/test-voice')
      },
    },
  }
</script>

<style>
  .container {
    padding: 16px;
    width: 50% !important;
  }

  .cancelbtn,
  .signupbtn {
    float: center;
    width: 50%;
    background-color: #36a8f4;
  }
</style>