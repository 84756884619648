import store from "@/store";
export default {
    data() {
        return { // refresh variables
            refreshing: false,
            registration: null,
            updateExists: false,
            isAppRegistered: false
        }
    },

    created() { // Listen for our custom event from the SW registration
        document.addEventListener('swUpdated', this.updateAvailable, {once: true})
        document.addEventListener('appOpened', this.appOpened, {once: true})

        // Prevent multiple refreshes
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing)
                return
            // Here the actual reload of the page occurs
            // window.location.reload()
            window.location = process.env.BASE_URL
            this.refreshing = true
        })
    },

    methods: {
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event) {
            this.registration = event.detail
            this.updateExists = true
        },
        appOpened() { // store.commit("setAppStatus", "visible");
            this.isAppRegistered = true
            this.$root.$emit('on_app_load')

        },
        // Called when the user accepts the update
        refreshApp() {
            console.log('refreshApp')
            // localStorage.clear();
            this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting)
                return

            // send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({action: "skipWaiting"})
        },
        checkInternetConnectionG() {
            if (store.state.authData.internetStatus == 'offline') {
                this.$toast.open({message: "Please check internet connection.", type: "error"});
            }
        }
    }
}
