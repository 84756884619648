export const environment = {
  production: false
};

// let base_url = ""
// base_url = process.env.VUE_APP_API_URL
let base_url = "https://careers.neosofttech.com/api/web/v1/vapp";
// let base_url = "http://180.149.241.208:3050/api/v1/manage"
class Endpoints {
  BASE_URL = base_url;
  AUTH_BASE = base_url;
  VENDER_BASE = base_url + "/vendor";
  USER_BASE = base_url + "/user";
  // IMAGE_BASE = 'http://localhost:3005' + '/public'
  IMAGE_BASE = "http://180.149.241.208:3050" + "/public";

  AUTH_ENDPOINTS = {
    LOGIN: this.joinPaths(this.AUTH_BASE, "send-otp"),
    verify_otp: this.joinPaths(this.AUTH_BASE, "verify-otp"),
    VALIDATE_USER: this.joinPaths(this.AUTH_BASE, "validate-user")
  };
  VIDEO_API_ENDPOINTS = {
    GET_POST_QUESTIONS: this.joinPaths(this.BASE_URL, "get-questions"),
    SKIP_QUESTIONS: this.joinPaths(this.BASE_URL, "skip-question"),
    SKIP_AUTO_QUESTIONS: this.joinPaths(this.BASE_URL, "auto-skip-question"),
    SUBMIT_QUESTIONS_VIDEO: this.joinPaths(
      this.BASE_URL,
      "submit-question-video"
    ),
    ANSWER_VERIFIES: this.joinPaths(this.BASE_URL, "answer-verified"),
    SKIPPED_QUESTIONS_BY_SYSTEM : this.joinPaths(this.BASE_URL, "system-skip-question"),
    CompletedInterview : this.joinPaths(this.BASE_URL, "completed-interview"),
    getAnswerSummary : this.joinPaths(this.BASE_URL, "get-answer-summary"),
    startQuestion : this.joinPaths(this.BASE_URL, "start-question")
  };

  joinPaths(...params) {
    const newUrl = params.join("/");
    return newUrl;
  }

  joinPathsWithQueryParams(...params) {
    const newUrl = params.join("?");
    return newUrl;
  }
}

const API = new Endpoints();
export default API;
