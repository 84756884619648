import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Ziggeo from 'vue-ziggeo'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/style.scss'
import '@/mixins/mixin.js'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import VueCarousel from 'vue-carousel'
import VueCountdownTimer from 'vuejs-countdown-timer'
import VueToast from 'vue-toast-notification'
// import VueSpeech from 'vue-speech'
import 'vue-toast-notification/dist/theme-sugar.css'
import "regenerator-runtime/runtime.js";
Vue.use(VueToast, {
  // One of the options
  position: 'top',
  duration:5000,
  dismissible:true,
  queue:true
});
// Vue.use(VueSpeech)
Vue.use(VueCountdownTimer)
Vue.use(VueCarousel);
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false
Vue.use(Ziggeo)
// const app = createApp(App)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
