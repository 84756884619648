<template>
  <div>
        <div class="profile-Img__content">
          <div class="video_recorder">
            <ziggeo-recorder
              :id="'recorder-embedding'"
               :apiKey="apiKey"
              :allowupload="false"
              :camerafacefront="true"
              :allowcustomupload="false"
              :picksnapshots="false"
              :showsettings="false"
              :skipseconds="true"
              :fullscreenmandatory="true"
              :showduration="true"
              :fittodimensions ="true"
              :width="370"
              :height="440"
              :recordingwidth="640"
              :recordingheight="480"
              @camera_unresponsive="recorderCameraUnresponsive"
              @access_forbidden="recorderAccessForbidden"
              @access_granted="recorderAccessGranted"
              @camera_nosignal="recorderNoSignal"
              @attached="recorderAttached"
              @ready_to_record="recorderReady"
              @countdown="checkCountDownTime"
              @recording="startRecording"

              @uploading="uploadingReacording"
              @upload_progress="uploadProgress"
              @uploaded="onUploaded"
              @verified="onProcessed"
              @processing="verifiedVideo"
              @error="checkError"
            ></ziggeo-recorder>
          </div>
          <div class="que">
            <p v-html="questionDetail.que_question"></p>
            <p v-if="isDataLoading">Please wait, video is uploading...</p>
          </div>
        </div>
        <div class="buttonClass">
          <button
          class="btn submit_ans"
          type="submit"
          @click="stopRecording()"
          v-if="!isDataLoading && countDown == 0"
        >
          Submit your answer
        </button>
        </div>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import credentials from "@/credentials";
import { mapGetters, mapActions, mapState } from "vuex";
import { defaultTime } from "@/constants/consts";
import {
  currentTime,
  setEndTime,
  timeFormat,
  timeDiff
} from "@/services/DateFormat.js";

// import Loader from '@/components/Loader'
export default {
  name: "recording",
  components: {
    // Loader
  },
  data() {
    const now = new Date();
    const newYear = new Date(now.getFullYear() + 1, 0, 1);

    return {
      questions: [],
      time: newYear - now,
      apiKey: "",
      recorderInstance: undefined,
      questionId: "",
      videoid: "",
      isDataLoading: false,
      embeddingcode: "",
      startTime: "",
      endTime: "",
      recordingEndedTime:'',
      timeLimit: defaultTime.expected_answer_time,
      countDown: 6,
      duration: "",
      questionDetail: {},
      redirectToLogin: false,
      original_stream_token:'',
      isVideoUploaded:false
    };
  },
  computed: {
    ...mapState(["authData", "questionsVideo"]),
    ...mapGetters([
      "loggedInUser",
      "allQuestions",
      "questionVideoArr",
      "currentQuestionDetail",
      "currentQuestion",
      "loading",
      "error",
      "questionSetWithChild",
      "interviewQuestionCount",
      'appLivestatus'
    ])
  },
  watch: {
      async appLivestatus(value, oldValue){
        console.log('appLivestatus value', value)
          if(value == 'hidden'){
            // await this.stopRecording()
            if(!this.isDataLoading &&  this.countDown < 1 ){
              this.recordingEndedTime = currentTime();
              this.redirectToLogin = true
              await this.recorderInstance.stop();
            }
            }
           if(value == 'visible'){
            // await this.skipQuestion('system_skipped')
          }
        },
        internetStatus(newVal, oldVal) {
            if (newVal == 'offline') {
             this.$store.commit("setAppStatus", "lostInternetConnection");
            }
        }
    },
  beforeMount() {
    this.apiKey = credentials.API_KEY;
  },
  mounted() {
    this.questions = this.allQuestions;
    this.questionDetail = this.currentQuestionDetail;
    var element = document.getElementById("recorder-embedding");
    var embedding = ZiggeoApi.V2.Recorder.findByElement(element);
    this.embeddingcode = embedding;
    embedding.activate();
    embedding.toggle_face_mode = true
    embedding.record_video = true
    embedding.record();
    // embedding.rerecord();
    // console.log('isRecording')
    // console.log(embedding.isRecording())

    this.startTime = currentTime();
    const endTimeLimit =
      this.questionDetail.time_to_read && this.questionDetail.time_to_read > 0
        ? this.questionDetail.time_to_read
        : this.timeLimit;
    this.endTime = setEndTime(this.startTime, endTimeLimit);
    this.questionId = this.$route.query.id;
  
  },

  created() {
    window.addEventListener("beforeunload", function(event) {
      return false;
    });
  },
  methods: {
    ...mapActions(["submitAnswer",'validateUser','signOut','setQuestionViewed']),
    startCallBack: function(x) {
      console.log(x);
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    stopRecording(verifiedVideo) {
      console.log('stopRecording', verifiedVideo)
      this.isDataLoading = true;
       this.recordingEndedTime = currentTime();
      this.recorderInstance.stop();
    },
    checkChildQuestion(qdata, index = 0) {
      console.log('checkChildQuestion', qdata)
      if (qdata.childQuestions.length > 0) {
        this.$store.commit(
          "setInterviewQuestionCount",
          qdata.childQuestions.length
        );
        this.$store.commit("setInterviewQuestion", {
          data: qdata.childQuestions,
          index: index + 1
        });
      } else {
        this.$store.commit("setInterviewQuestionCount", 1);
        this.$store.commit("setInterviewQuestion", {
          data: qdata,
          index: index + 1
        });
      }
    },
    async onUploaded(verifiedVideo) {
        try {
           console.log('onUploaded', verifiedVideo)
        }catch(error){
          console.log('error 3')
        }
    },
    async onProcessed(verifiedVideo) {
      try {
      console.log('onProcessed', verifiedVideo)
      this.isDataLoading = true;
      this.componentKey = false;
      this.videoid = verifiedVideo.get("video");

      const qdata = this.questionDetail;
      qdata.isSkipped = false;
      qdata.videoId = verifiedVideo.get("video");
      const validateUser = await this.validateUser()

      const link = `https://embed.ziggeo.com/v1/applications/${this.apiKey}/videos/${qdata.videoId}`;
      const response = await fetch(link);
      const resData = await response.json();
      const uploadedAt = currentTime();
      console.log('uploadedAt',uploadedAt)
    // const uploadedAt = "2022-01-31 10:00:00"

      let vDuration = resData.duration &&  resData.duration != null   && resData.duration > 0
        ? resData.duration
        : resData.default_stream.duration;
        console.log('vDuration', vDuration)
        // this.original_stream_token = resData.original_stream ? resData.original_stream.token : ''

        // console.log('this.uploadedAt ' ,this.startTime )
        // console.log('this.uploadedAt ' ,timeDiff(this.startTime))

        if(vDuration == null) {
          vDuration = timeDiff(this.startTime);
        }

      if(validateUser != '' && validateUser.data == true) {
          // console.log('validateUser' , validateUser);
          const formData = {
            interview_id: this.loggedInUser.interview_id,
            question_id: this.questionDetail.que_id,
            start_time: timeFormat(this.startTime),
            end_time: timeFormat(this.recordingEndedTime),
            uploaded_at : timeFormat(uploadedAt),
            video_id: qdata.videoId,
            video_link: "https://" + resData.embed_video_url + ".mp4",
            video_duration: vDuration
          };
          await this.submitAnswer(formData)
            .then(res => {
              console.log('res', res)
              if (this.error == null) {
                this.$store.dispatch("setVideo", this.questionDetail);
                this.isVideoUploaded = true
              }
            })
            .catch(err => {
              this.$toast.open({
                message: "Something went wrong",
                type: "error"
              });
          });
          console.log('video submitted.')
          console.log('this.error', this.error)
          if (this.error == null) {
            let qIndex = this.questionSetWithChild.findIndex(
              x => x.que_id === this.questionDetail.que_id
            );
            let index = qIndex;
            let cQ;
            if (this.questionDetail.childQuestions.length > 0) {
              if (qIndex == -1) {
                index = this.questionSetWithChild.length;
              }

              this.checkChildQuestion(qdata, index);
              if (qIndex == -1) {
                cQ = this.questionSetWithChild[index];
              } else {
                cQ = this.questionSetWithChild[index + 1];
              }
              // this.questionDetail =nitem
              this.$store.commit("setquestionDetail", cQ);
               await this.setQuestionViewed();
            } else {
              const remaningquestion = this.questionSetWithChild.filter(
                ({ que_id: id1 }) =>
                  !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1)
              );
              if (remaningquestion.length > 0) {
                cQ = this.questionSetWithChild[this.questionVideoArr.length];
                this.$store.commit("setquestionDetail", cQ);
                 await this.setQuestionViewed();
              } else {
                const results = this.allQuestions.filter(
                  ({ que_id: id1 }) =>
                    !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1)
                );
                if (results.length > 0) {
                  cQ = results[0];
                  this.$store.commit("setquestionDetail", cQ);
                   await this.setQuestionViewed();
                } else {
                  this.$router.push("/thankyou");
                  // return;
                }
              }
            }
            this.verifiedVideo(verifiedVideo)
          } else {
            console.log('Something went wrong.')
            this.$toast.open({
              message: "Something went wrong.",
              type: "error"
            });
          }
        } else {
          console.log('deleteRecordedVideo')
          // this.deleteRecordedVideo( this.apiKey, qdata.videoId, this.original_stream_token )
        }
         }catch(error){
        }
    },
    async verifiedVideo(verifiedVideo) {
      console.log('this.isVideoUploaded ', this.isVideoUploaded )
      if(this.isVideoUploaded == true) {
        console.log('verifiedVideo', verifiedVideo)

          this.isVideoUploaded = false;
          const results = this.allQuestions.filter(
            ({ que_id: id1 }) =>
              !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1)
          );

          const remaningquestion = this.questionSetWithChild.filter(
            ({ que_id: id1 }) =>
              !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1)
          );

        if (results.length == 0 && remaningquestion.length == 0) {
          this.$router.push("/thankyou");
          return;
        }
        console.log('this.redirectToLogin', this.redirectToLogin)
        if(this.redirectToLogin){
          this.$store.commit('resetQuestionData')
          await this.signOut()
          this.redirectToLogin = false
          this.$store.commit('resetQuestionData')
          localStorage.removeItem("vuex");
          this.$router.push("/");
          return
        }
         this.$router.push("/questions");
      }
    },
    recorderReady(instance) {
      console.log('recorderReady', instance)
      this.recorderInstance = instance;
    },
    startRecording(){
       console.log('startRecording')
         this.countDownTimer();
    },
    checkCountDownTime(time){
       console.log('checkCountDownTime-', time)
    },
    recorderAttached(embed) {
       console.log('recorderAttached', embed)

      this.recorderInstance = embed;
    },
    changeTheme() {
      if (typeof this.recorderInstance !== "undefined") {
        this.recorderInstance.set(
          "themecolor",
          recorderInstance.get("themecolor") === "red" ? "blue" : "red"
        );
        console.log(
          "Current color >> ",
          this.recorderInstance.get("themecolor")
        );
      }
    },
    async uploadProgress(uploaded, total) {
      // const validateUser = await this.validateUser()
      // console.log('validateUser-----', validateUser)
      // if(validateUser && validateUser.data == false) {
      //   this.$toast.open({
      //         message: "Your session expired.",
      //         type: "error"
      //       });
      //   await this.signOut();
      //   localStorage.removeItem("vuex");
      //   this.$router.push("/");
      // }
        try {
          console.log('----')
          console.log('uploadProgress', uploaded, '  total ', total)
          if (total == NaN || total == 'NaN' || total == 'nan   b  ' || isNaN(total)) {
            console.log('iff in .. nan')
            // alert('error')
             this.$toast.open({
                message: "Some internal issue in recording , please record video again",
                type: "error"
              });
                this.isDataLoading = false;
                console.log('embeddingcode',  this.embeddingcode )
                this.embeddingcode.activate();
                this.embeddingcode.toggle_face_mode = true
                this.embeddingcode.record_video = true
                this.embeddingcode.record();
                this.startTime = currentTime();
          }
          this.isDataLoading = true;
        } catch(error) {
          console.log('error', error)
        }
    },
    uploadingReacording(embed){
      try {
           console.log('uploadingReacording', embed)
        }catch(error){
          console.log('error 2')
        }
    },
    recordingStopped(verifiedVideo) {
      this.duration = timeDiff(this.startTime);
    },
    recorderCameraUnresponsive(verifiedVideo) {
      this.isDataLoading = true
      this.$toast.open({
        message: "Camera unresponsive.",
        type: "error"
      });
      this.$router.push("/questions");

    },
    recorderAccessForbidden(verifiedVideo) {
      navigator.mediaDevices
      .getUserMedia({ video: true, camera: true, microphone: true })
      .then(async mediaStream => {
        })
      .catch(error => {
          this.isDataLoading = true
          this.$toast.open({
            message: "Camera access forbidden",
            type: "error"
          });
          this.$router.push('/check-permission')
      });
    },
    recorderAccessGranted() {
      console.log("recorderAccessGranted");
    },
    recorderNoSignal() {
      this.isDataLoading = true
      this.$toast.open({
        message: "camera has no signal",
        type: "error"
      });
      this.$router.push("/questions");
    },
    errorInRecording(error_type, error_code) {
      console.log(error_type,error_code )
      this.$toast.open({
        message: "Something went wrong",
        type: "error"
      });
      this.embeddingcode.rerecord();
    },
    noCamera() {
       console.log('noCamera')
      this.$toast.open({
        message: "Camera not accessible",
        type: "error"
      });
    },
    noMicrophone() {
       console.log('noMicrophone')
      this.$toast.open({
        message: "Microphone not accessible",
        type: "error"
      });
    },
    recordingVideo(){
      console.log('recording')
    },
    checkError(error_type, error_code) {
      console.log('error_type, error_code')
      console.log(error_type, error_code)
    },
    async deleteRecordedVideo(apiKey, videoId, original_stream_token){
      // console.log(apiKey, 'videoId........' ,videoId, 'original_stream_token..........' ,original_stream_token)
          const link = `https://embed.ziggeo.com/v1/applications/${apiKey}/videos/${videoId}/streams/${original_stream_token}`;
          await fetch(link, {
                      method: 'delete'
                    })
                .then(response => response.json());
                this.$toast.open({
                  message: "Your session expired.",
                  type: "error"
                });
        await this.signOut();
        this.$store.commit('resetQuestionData')
        localStorage.removeItem("vuex");
        this.$router.push("/");
      //    /v1/applications/*application_token*/videos/*video_token_or_key*/streams/*stream_token*
    }
  }
};
</script>
