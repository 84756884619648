// import firebase from "firebase/app"
// import "firebase/auth"
import apiHandler from "@/services/Api.js";
import API from "@/constants/api-endpoints.js";
import AuthToken from "@/services/AuthToken";
import store from "@/store";
import router from "@/router";
export default {
  state: {
    loggedInUser:
      localStorage.getItem("authToken") != null
        ? localStorage.getItem("authToken")
        : null,
    // loggedInUser:    {
    //       mobile_no: "9999999999",
    //       user_name: "Kartik"
    //     },
    responseData:null,
    loading: false,
    error: null,
    isUserSessionValie:false,

    internetStatus:null
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    loading: state => state.loading,
    error: state => state.error,
    responseData:state => state.responseData,
    internetStatus:state => state.internetStatus,
    isUserSessionValie:state => state.isUserSessionValie
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data;
      state.loading = false;
      state.error = null;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.questionVideoArr = [];
      state.currentQuestion = null;
      state.questionDetail = {};
      state.questionSetWithChild = [];
      state.interviewQuestionCount = 0;
      state.allQuestions = [];
      state.loading = false;
      state.error = null;
      localStorage.removeItem("vuex");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userInfo");
    },
    setLoading(state, data) {
      state.loading = data;
      state.error = null;
    },
    setResponseData(state, data) {
      state.responseData = data;
      state.error = null;
    },
    setInternetConnection(state, data) {
      state.internetStatus = data;
    },
    setUserSession(state, data) {
      state.isUserSessionValie = data;
    },
    setError(state, data) {
      state.error = data;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    }
  },
  actions: {
    async login({ commit }, data) {
      commit("setLoading", true);
      commit("setResponseData",null);
      commit("setUser", null);

      commit("clearError");
      await apiHandler
        .post(API.AUTH_ENDPOINTS.LOGIN, data)
        .then(res => {
          if (res.data.status == true) {
            this.isLoading = false;
            const userData = res.data.data.candidate;
            userData.is_verified = false;
            commit("setUser", userData);
            return userData;
          }
          else {
            commit("setError", res.data);
          }
        })
        .catch(err => {
          commit("setError", err);
        });
    },
    async verifyOtp({ commit }, data) {
      commit("setLoading", true);
      commit("clearError");
      await apiHandler
        .post(API.AUTH_ENDPOINTS.verify_otp, data)
        .then(res => {
          if (res.data.status == true) {
            const userData = res.data.data.candidate;
            userData.user_name = userData.name.split(" ")[0];
            userData.is_verified = true;
            commit("setUser", userData);
            AuthToken.saveToken(res.data.data.token)
            return userData;
          } else {
            commit("setError", res.data);
          }
        })
        .catch(err => {
          commit("setError", err);
        });
    },
    async validateUser({ commit }, data) {
      commit("clearError");
      const res =  await apiHandler
        .post(API.AUTH_ENDPOINTS.VALIDATE_USER, data)
        .then(res => {
          commit("setUserSession", res.data);
          return res.data
        })
        .catch(err => {
          commit("setError", err);
        });
        return res
    },
    async checkInternetConnection({ commit }) {
        // try {
        //     const online = await fetch("https://google.com");
        //     console.log('Became online', online )
        //     commit("setInternetConnection", 'online');
        //     return online.status >= 200 && online.status < 300; // either true or false
        //   } catch (err) {
        //     commit("setInternetConnection", 'offline');

        //      console.log('Became offline')
        //     return false; // definitely offline
        //   }
        let test = ''
          await fetch('https://google.com', {
              method: 'GET', // *GET, POST, PUT, DELETE, etc.
              mode: 'no-cors',
          }).then((result) => {
            test = 'online'
            // commit("setInternetConnection", 'online');
          }).catch(e => {
            test = 'offline'
           
          })
          commit("setInternetConnection", test);
    },
    signOut({ commit }) {
      localStorage.removeItem("vuex");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userInfo");
      commit("setLogout");
    }
  }
};
