<template>
  <div>
        <div class="profile-Img__content">
          <div class="video_recorder">
            <ziggeo-recorder
              :id="'recorder-embedding'"
              :apiKey="apiKey"
              :allowupload="false"
              :camerafacefront="true"
              :allowcustomupload="false"
              :picksnapshots="false"
              :showsettings="false"
              :skipseconds="true"
              :fullscreenmandatory="true"
              :showduration="true"
              :fittodimensions ="true"
              :width="370"
              :height="440"
              :recordingwidth="640"
              :recordingheight="480"
              @camera_unresponsive="recorderCameraUnresponsive"
              @access_forbidden="recorderAccessForbidden"
              @access_granted="recorderAccessGranted"
              @camera_nosignal="recorderNoSignal"
              @attached="recorderAttached"
              @ready_to_record="recorderReady"
              @verified="verifiedVideo"
              @uploading="uploadingReacording"
              @upload_progress="uploadProgress"
              @uploaded="onUploaded"
              @error="checkError"
            ></ziggeo-recorder>
          </div>
        </div>
        <div class="buttonClass">
          <button
              class="btn submit_ans"
              type="submit"
              @click="stopRecording()">
            Stop
          </button>
        </div>
  </div>
</template>
<script>
import credentials from "@/credentials";
import { mapGetters, mapActions, mapState } from "vuex";
import { defaultTime } from "@/constants/consts";
import {
  currentTime,
  setEndTime,
  timeFormat,
  timeDiff
} from "@/services/DateFormat.js";

// import Loader from '@/components/Loader'
export default {
  name: "recording",
  components: {
    // Loader
  },
  data() {
    const now = new Date();
    const newYear = new Date(now.getFullYear() + 1, 0, 1);

    return {
      questions: [],
      time: newYear - now,
      apiKey: "",
      recorderInstance: undefined,
      questionId: "",
      videoid: "",
      isDataLoading: false,
      embeddingcode: "",
      startTime: "",
      endTime: "",
      timeLimit: defaultTime.expected_answer_time,
      countDown: 8,
      duration: "",
      questionDetail: {},
      redirectToLogin: false,
      original_stream_token:''
    };
  },
  computed: {
    ...mapState(["authData", "questionsVideo"]),
    ...mapGetters([
      "loading",
      "error",
    ])
  },
  beforeMount() {
    this.apiKey = credentials.API_KEY;
    console.log( 'API_KEY', credentials.API_KEY)
  },
  mounted() {
    var element = document.getElementById("recorder-embedding");
    var embedding = ZiggeoApi.V2.Recorder.findByElement(element);
    this.embeddingcode = embedding;
    console.log('embeddingcode', embedding)
    embedding.activate();
    embedding.toggle_face_mode = true
    embedding.record_video = true
    embedding.record();
    this.startTime = currentTime();
    this.endTime = setEndTime(this.startTime, 90);
  },

  created() {
    window.addEventListener("beforeunload", function(event) {
      return false;
    });
  },
  methods: {
    ...mapActions(["submitAnswer",'validateUser','signOut']),
    startCallBack: function(x) {
      console.log(x);
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    stopRecording(verifiedVideo) {
      console.log('stopRecording', verifiedVideo)
      this.isDataLoading = true;
      this.recorderInstance.stop();
    },
    async onUploaded(verifiedVideo) {
      console.log('verifiedVideo...', verifiedVideo)
      this.isDataLoading = true;
      this.componentKey = false;
      this.videoid = verifiedVideo.get("video");

      const qdata = []
      qdata.isSkipped = false;
      qdata.videoId = verifiedVideo.get("video");
      // const validateUser = await this.validateUser()
      const validateUser = {}

      const link = `https://embed.ziggeo.com/v1/applications/${this.apiKey}/videos/${qdata.videoId}`;
      const response = await fetch(link);
      const resData = await response.json();
      console.log(resData)

      let vDuration = resData.duration && resData.duration > 0
        ? resData.duration
        : resData.default_stream.duration;
        this.original_stream_token = resData.original_stream_token

        console.log('this.validateUser', validateUser)
        console.log('this.vDuration', vDuration)
        console.log('this.original_stream_token',this.original_stream_token)
    },
    async verifiedVideo(verifiedVideo) {
      console.log('verifiedVideo', verifiedVideo)
       console.log(verifiedVideo.get());
      this.isDataLoading = false;
    },
    recorderReady(instance) {
      console.log('recorderReady', instance)
      console.log(instance.get());
      this.recorderInstance = instance;
    },
    recorderAttached(embed) {
      console.log(embed.get());
      console.log('recorderAttached', embed)

      this.recorderInstance = embed;
    },
    async uploadProgress(uploaded, total) {
      console.log('uploadProgress  uploaded :', uploaded, '  total :', total)
      this.isDataLoading = true;
    },
    uploadingReacording(embed){
      console.log('uploadingReacording', embed)


    },
    recordingStopped(verifiedVideo) {
       console.log('recordingStopped', verifiedVideo)
      this.duration = timeDiff(this.startTime);
    },
    recorderCameraUnresponsive(verifiedVideo) {
       console.log('recorderCameraUnresponsive', verifiedVideo)

      this.isDataLoading = true
      this.$toast.open({
        message: "Camera unresponsive.",
        type: "error"
      });

    },
    recorderAccessForbidden(verifiedVideo) {
      console.log('recorderAccessForbidden', verifiedVideo)

      this.isDataLoading = true
      this.$toast.open({
        message: "Camera access forbidden",
        type: "error"
      });
    },
    recorderAccessGranted() {
      console.log("recorderAccessGranted");
    },
    recorderNoSignal() {
      this.isDataLoading = true
      this.$toast.open({
        message: "camera has no signal",
        type: "error"
      });
    },
    errorInRecording(error_type, error_code) {
      console.log(error_type,error_code )
      this.$toast.open({
        message: "Something went wrong",
        type: "error"
      });
      this.embeddingcode.rerecord();
    },
    noCamera() {
       console.log('noCamera')
      this.$toast.open({
        message: "Camera not accessible",
        type: "error"
      });
    },
    noMicrophone() {
       console.log('noMicrophone')
      this.$toast.open({
        message: "Microphone not accessible",
        type: "error"
      });
    },
    recordingVideo(){
      console.log('recording')
    },
  
    checkError(error_type, error_code){
      console.log('error_type  ', error_type)
      console.log('error_code ', error_code)
    },
    selectImage(image){
      console.log('the image object representing the selected image', image)
    }
  }
};
</script>
